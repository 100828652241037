import { default as loginikXFF2hTKwMeta } from "/app/pages/auth/login.vue?macro=true";
import { default as index4aGTGBC5tJMeta } from "/app/pages/home/index.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexxcTSHgrLZMMeta } from "/app/pages/manager/foods/index.vue?macro=true";
import { default as indexwOgXzAz6usMeta } from "/app/pages/manager/ingredients/index.vue?macro=true";
import { default as new_45ingredientbFAr5AcDmQMeta } from "/app/pages/manager/ingredients/new-ingredient.vue?macro=true";
import { default as index1uJqNsFqGxMeta } from "/app/pages/manager/menu/index.vue?macro=true";
import { default as new_45menuLLJZ1xVJF9Meta } from "/app/pages/manager/menu/new-menu.vue?macro=true";
import { default as indexvvhlv5DLCsMeta } from "/app/pages/messenger/index.vue?macro=true";
import { default as indexCICQ7TchH0Meta } from "/app/pages/order/index.vue?macro=true";
export default [
  {
    name: "auth-login",
    path: "/auth/login",
    meta: loginikXFF2hTKwMeta || {},
    component: () => import("/app/pages/auth/login.vue")
  },
  {
    name: "home",
    path: "/home",
    meta: index4aGTGBC5tJMeta || {},
    component: () => import("/app/pages/home/index.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexN6pT4Un8hYMeta || {},
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "manager-foods",
    path: "/manager/foods",
    meta: indexxcTSHgrLZMMeta || {},
    component: () => import("/app/pages/manager/foods/index.vue")
  },
  {
    name: "manager-ingredients",
    path: "/manager/ingredients",
    meta: indexwOgXzAz6usMeta || {},
    component: () => import("/app/pages/manager/ingredients/index.vue")
  },
  {
    name: "manager-ingredients-new-ingredient",
    path: "/manager/ingredients/new-ingredient",
    meta: new_45ingredientbFAr5AcDmQMeta || {},
    component: () => import("/app/pages/manager/ingredients/new-ingredient.vue")
  },
  {
    name: "manager-menu",
    path: "/manager/menu",
    meta: index1uJqNsFqGxMeta || {},
    component: () => import("/app/pages/manager/menu/index.vue")
  },
  {
    name: "manager-menu-new-menu",
    path: "/manager/menu/new-menu",
    meta: new_45menuLLJZ1xVJF9Meta || {},
    component: () => import("/app/pages/manager/menu/new-menu.vue")
  },
  {
    name: "messenger",
    path: "/messenger",
    component: () => import("/app/pages/messenger/index.vue")
  },
  {
    name: "order",
    path: "/order",
    component: () => import("/app/pages/order/index.vue")
  }
]
import payload_plugin_9twEX7uvJv from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.21.3_typescript@5.6.3_vue@3.5.6_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_SGuEYefSI3 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_qTEefNpAB1 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_ePI1mnYLPU from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_ochTmB7uqX from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_K3oSLLgUEa from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3ZGTl5soG3 from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_x4CUjGlTNm from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_hIgMWwBNBd from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_BRiXSpIHSk from "/app/node_modules/.pnpm/@pinia+nuxt@0.7.0_magicast@0.3.5_rollup@4.21.3_typescript@5.6.3_vue@3.5.6_typescript@5.6.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_17XiUBDXVg from "/app/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.4.1_@types+node@22.5.5_ioredis@5.4.1_magicast@0.3.5_rollup@4.21_ulgvyoughasobdaysp6gsget6q/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_v1sFdLnaB6 from "/app/node_modules/.pnpm/@nuxtjs+color-mode@3.5.1_magicast@0.3.5_rollup@4.21.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import auth_redirect_5EDtur7iPR from "/app/node_modules/.pnpm/@nuxtjs+supabase@1.4.0/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
export default [
  payload_plugin_9twEX7uvJv,
  revive_payload_client_SGuEYefSI3,
  unhead_qTEefNpAB1,
  router_ePI1mnYLPU,
  supabase_client_ochTmB7uqX,
  payload_client_K3oSLLgUEa,
  navigation_repaint_client_3ZGTl5soG3,
  check_outdated_build_client_x4CUjGlTNm,
  chunk_reload_client_hIgMWwBNBd,
  plugin_vue3_BRiXSpIHSk,
  components_plugin_KR1HBZs4kY,
  prefetch_client_17XiUBDXVg,
  plugin_client_v1sFdLnaB6,
  auth_redirect_5EDtur7iPR
]